body {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  background: #f7fafc;
  font-size: 20px;
}

#gmap_canvas img {
  max-width: none !important;
  background: none !important;
}

.ql-editor {
  min-height: 18em;
}
